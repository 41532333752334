<template>
  <div>
    <div class="flex justify-center">
      <div>
        <h3>میتوانید با استفاده از این فیلد و وارد کردن ID آن نیازمندی های ورژن آن ایمیج را تعیین کنید</h3>
        <vs-input
          type="number"
          icon-after="false"
          class="my-3 w-full"
          label="template id"
          v-model="template_id"
        ></vs-input>
        <p
          class="text-danger"
          v-if="template_id.length == 0"
          style="font-size:13px"
        >
          خطا: این فیلد تنها عدد میپذیرد
        </p>
        <p
          class="text-danger"
          v-if="template_id.length == 0"
          style="font-size:13px"
        >
          خطا: این فیلد اجباری است
        </p>
        <vs-input
          type="search"
          class="my-3 w-full"
          label="template version"
          v-model="template_version"
        ></vs-input>
        <p
          v-if="template_version.length == 0"
          class="text-danger"
          style="font-size:14px"
        >
          خطا: این فیلد اجباری است
        </p>
        <br />
        <p class="text-warning" style="font-size:14px">هشدار: برای افزودن نیازمندی های ورژن یا ورژن های یک ایمیج, ورژن ایمیج های <br>مورد نظر را وارد کرده و حتما با کاما (,) انها را جدا کنید. 
        </p>
        <br />
        <p class="text-success" style="font-size:14px">
          EXAMPLE: 20.04
        </p>
        <div
          style="background-color:#10163A; border-radius: 5px; font-size:13px"
          class="my-1"
        >
          <ul class="leftx">
            <li class="modelx">
              <!-- {{ radios1 }} -->
            </li>
            <li>
              <vs-radio v-model="is_QEMU" vs-name="is QEMU" vs-value="1"
                >این ایمیج یک QEMU است</vs-radio
              >
            </li>
            <li>
              <vs-radio v-model="is_QEMU" vs-name="is not QEMU" vs-value="0"
                >این ایمیج یک ‌QEMU نیست</vs-radio
              >
            </li>
          </ul>
        </div>
        <vs-input
          v-if="is_QEMU == '1'"
          type="number"
          class="my-3 w-full"
          label="clone node id"
          v-model="clone_node_id"
        ></vs-input>
        <p
          v-if="clone_node_id.length == 0"
          class="text-danger"
          style="font-size:14px"
        >
          خطا: این فیلد اجباری است
        </p>
        <vs-input
          v-if="is_QEMU == '1'"
          type="number"
          class="my-3 w-full"
          label="clone sequence id"
          v-model="clone_sequence_id"
        ></vs-input>
        <p
          v-if="clone_sequence_id.length == 0"
          class="text-danger"
          style="font-size:14px"
        >
          خطا: این فیلد اجباری است
        </p>
        <vs-input
          type="number"
          class="my-3 w-full"
          label="حداقل هسته پردازنده برای این ایمیج(CORE)"
          v-model="min_vcpu"
        ></vs-input>
        <p
          v-if="min_vcpu < 1 || min_vcpu > 16"
          class="text-warning"
          style="font-size:14px"
        >
          حداکثر = 16 | حداقل = 1
        </p>
        <vs-input
          type="number"
          class="my-3 w-full"
          label="حداقل رم مورد نیاز این ایمیج(GB)"
          v-model="min_ram"
        ></vs-input>
        <p
          v-if="min_ram < 1 || min_ram > 32"
          class="text-warning"
          style="font-size:14px"
        >
          حداکثر = 32 | حداقل = 1
        </p>
        <vs-input
          type="number"
          class="my-3 w-full"
          label="حداقل هارد برای این ایمیج (GB)"
          v-model="min_hard"
        ></vs-input>
        <p
          v-if="min_hard < 5 || min_hard > 100"
          class="text-warning"
          style="font-size:14px"
        >
          حداکثر = 100 | حداقل = 5
        </p>
        <vs-button class="w-full" v-if="validator" @click="adddetilsimage()">SUBMIT</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_QEMU: null,
      template_id: "",
      template_version: "",
      clone_node_id: 2,
      clone_sequence_id: 99970,
      min_vcpu: 5,
      min_ram: 5,
      min_hard: 5
    };
  },
  computed: {
    validator() {
      if (
        this.template_id &&
        this.template_version &&
        this.clone_node_id &&
        this.clone_sequence_id &&
        this.min_vcpu &&
        this.min_ram &&
        this.min_hard
      ) {
        if (1 <= this.min_vcpu < 16) {
          if (1 <= this.min_ram < 32) {
            if (5 <= this.min_hard < 100) {
              return true;
            }
          }
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    adddetilsimage() {
      const data = {
        template_id: this.template_id,
        template_version: this.template_version,
        clone_node_id: this.clone_node_id,
        clone_sequence_id: this.clone_sequence_id,
        min_vcpu:this.min_vcpu,
        min_ram: this.min_ram,
        min_hard: this.min_hard
      };
      this.$http
        .post("/admin/vira/cloud/image/add/details", data)
        .then(result => {
          this.$vs.notify({ text: result.data.message, color: "success" });
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    },
  }
};
</script>

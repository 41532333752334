<template>
  <div>
    <vs-tabs>
      <vs-tab label="Add image">
        <div>
          <addimage />
        </div>
      </vs-tab>
      <vs-tab label="Add image details">
        <div>
          <detailsimage />
        </div>
      </vs-tab>
      <vs-tab label="Update image">
        <div>
          <updateimage />
        </div>
      </vs-tab>
    </vs-tabs>
    <div
      style="background-color:#10163A; border-radius: 5px; font-size:13px"
      class="my-1"
    >
      <ul class="leftx">
        <li class="modelx">
          <!-- {{ radios1 }} -->
        </li>
        <li>
          <vs-radio v-model="image_type" vs-name="is not QEMU" vs-value="1"
            >list only LXC</vs-radio
          >
        </li>
        <li>
          <vs-radio v-model="image_type" vs-name="is not QEMU" vs-value="3"
            >list only QEMU</vs-radio
          >
        </li>
        <li>
          <vs-radio v-model="image_type" vs-name="is not QEMU" vs-value="2"
            >list only LXC_App</vs-radio
          >
        </li>
        <li>
          <vs-radio v-model="image_type" vs-name="is not QEMU" vs-value="4"
            >list only QEMU_App</vs-radio
          >
        </li>
        <li>
          <vs-radio v-model="image_type" vs-name="ALL" vs-value="0"
            >Log all data in browser console</vs-radio
          >
        </li>
      </ul>
    </div>
    <vs-button
      class="my-2 w-full mar flex justify-center"
      @click="getalltemplate(image_type)"
      >List images</vs-button
    >
    <vs-row>
      <div class="w-full">
        <vs-table :data="imageinfo">
          <template slot="header"> </template>

          <template slot="thead">
            <vs-th>
              ID
            </vs-th>
            <vs-th>
              عنوان
            </vs-th>
            <vs-th>
              ورژن
            </vs-th>
            <vs-th>
              توضیحات
            </vs-th>
            <vs-th>
              نوع
            </vs-th>
            <vs-th>
              APP است
            </vs-th>
            <vs-th>
              Containo است
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>
              <vs-td :data="data[indextr].title">
                {{ data[indextr].title }}
              </vs-td>
              <vs-td :data="data[indextr].versions">
                {{ data[indextr].versions }}
              </vs-td>
              <vs-td :data="data[indextr].description">
                {{ data[indextr].description }}
              </vs-td>
              <vs-td :data="data[indextr].type_text">
                {{ data[indextr].type_text }}
              </vs-td>
              <vs-td
                :class="data[indextr].is_app ? 'text-success' : 'text-danger'"
                :data="data[indextr].is_app"
              >
                {{ data[indextr].is_app ? "is app" : "not app" }}
              </vs-td>
              <vs-td
                :class="
                  data[indextr].is_containo ? 'text-success' : 'text-danger'
                "
                :data="data[indextr].is_containo"
              >
                {{
                  data[indextr].is_containo ? "is containo" : " not containo"
                }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-row>
  </div>
</template>
<script>
import addimage from "./addimage.vue";
import detailsimage from "./detailsimage.vue";
import updateimage from "./updateimage.vue";
export default {
  data() {
    return {
      image_type: null,
      imageinfo: []
    };
  },
  components: {
    addimage,
    detailsimage,
    updateimage
  },
  methods: {
    getalltemplate(val) {
      this.$http.get("/vira/cloud/image/template?type=0").then(result => {
        if (val == 0) {
          console.log(result.data.params.data);
          // this.imageinfo = result.data.params.data
        }
        if (val == 1) {
          // console.log(result.data.params.data.LXC);
          this.imageinfo = result.data.params.data.LXC;
        }
        if (val == 2) {
          // console.log(result.data.params.data.LXC_App);
          this.imageinfo = result.data.params.data.LXC_App;
        }
        if (val == 3) {
          // console.log(result.data.params.data.QEMU);
          this.imageinfo = result.data.params.data.QEMU;
        }
        if (val == 4) {
          // console.log(result.data.params.data.QEMU_App);
          this.imageinfo = result.data.params.data.QEMU_App;
        }
        // console.log(result.data.params.data.LXC);
      });
      // .then(() =>{
      // console.log("***",this.imageinfo);
      // })
    }
  }
};
</script>

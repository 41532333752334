<template>
  <div>
    <div class="flex justify-center">
      <div>
        <h3>میتوانید با استفاده از لیست زیر ایمیج اضافه کنید :</h3>
        <vs-input
          type="search"
          class="my-3 w-full"
          label="عنوان"
          v-model="title"
        ></vs-input>
        <p class="text-warning" v-if="title.length == 0" style="font-size:13px">
          هشدار: بهتر است این فیلد را پر کنید
        </p>
        <vs-input
          type="search"
          class="my-3 w-full"
          label="ورژن"
          v-model="versions"
        ></vs-input>
        <p v-if="versions.length == 0" class="text-danger" style="font-size:14px">
          خطا: این فیلد اجباری است
        </p>
        <br />
        <p class="text-warning" style="font-size:14px">
          هشدار: ورژن هر ایمیج می بایست توسط کاما (,) از یکدیگر جدا شوند!!!
        </p>
        <br />
        <p class="text-success" style="font-size:14px">
          مثال: Docker-ce,Docker-ce <br>
          مثال: 18.04,20.04
        </p>
        <vs-input
          type="search"
          class="my-3 w-full"
          label="توضیحات"
          v-model="description"
        ></vs-input>
        <p
          class="text-warning"
          v-if="description.length == 0"
          style="font-size:13px"
        >
          هشدار: بهتر است این فیلد را پر کنید
        </p>
        <vs-collapse-item
          class="my-4 w-full"
          style="background-color:#10163A;   border-radius: 5px;"
        >
          <p slot="header" style="font-size:13px">نوع ایمیج  چیست ؟</p>
          <ul class="leftx">
            <li class="modelx">
              <!-- {{ radios1 }} -->
            </li>
            <li>
              <vs-radio v-model="type" vs-name="LXC" vs-value="1">LXC</vs-radio>
            </li>
            <li>
              <vs-radio v-model="type" vs-name="QEMU" vs-value="2"
                >QEMU</vs-radio
              >
            </li>
          </ul>
        </vs-collapse-item>
        <vs-collapse-item
          class="my-4"
          style="background-color:#10163A; border-radius: 5px;"
        >
          <p slot="header" style="font-size:13px">از نوع APP است؟</p>
          <ul class="leftx">
            <li class="modelx">
              <!-- {{ radios1 }} -->
            </li>
            <li>
              <vs-radio v-model="is_app" vs-name="is app" vs-value="1"
                >بلی</vs-radio
              >
            </li>
            <li>
              <vs-radio v-model="is_app" vs-name="is not app" vs-value="0"
                >خیر</vs-radio
              >
            </li>
          </ul>
        </vs-collapse-item>
        <vs-collapse-item
          class="my-4"
          style="background-color:#10163A; border-radius: 5px;"
        >
          <p slot="header" style="font-size:13px">نوع سیستم عامل ؟</p>
          <ul class="leftx">
            <li class="modelx">
              <!-- {{ radios1 }} -->
            </li>
            <li>
              <vs-radio v-model="os_type" vs-name="Linux" vs-value="1"
                >Linux base</vs-radio
              >
            </li>
            <li>
              <vs-radio v-model="os_type" vs-name="Windows" vs-value="2"
                >Windows base</vs-radio
              >
            </li>
          </ul>
        </vs-collapse-item>
        <vs-button v-if="validator" class="w-full" @click="addimage()">
          SUBMIT
        </vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      versions: '',
      description: '',
      type: null,
      is_app: null,
      os_type: null
    };
  },
  methods: {
    addimage() {
      const data = {
        title: this.title,
        versions: this.versions,
        description: this.description,
        type: this.type,
        is_app: this.is_app,
        os_type: this.os_type
      };
      this.$http
        .post("admin/vira/cloud/image/add", data)
        .then(result => {
          this.$vs.notify({ text: result.data.message, color:"success" });
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color:"danger" });
        });
    }
  },
  computed: {
    validator() {
      if (
        this.title &&
        this.versions &&
        this.type &&
        this.is_app &&
        this.os_type
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.mar {
  margin-top: 100px;
}
</style>

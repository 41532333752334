<template>
  <div>
    <div class="mar flex justify-center">
      <div>
        <h3 class="text-warning">
          میتوانید به ایمیج های از پیش ساخته شده شده یک یا چند ورژن افزوده یا
          حذف کنید
        </h3>
        <vs-input
          type="number"
          style="background-color:#500; border-radius:10px"
          class="my-2 w-full"
          label="template id"
          v-model="template_id"
        ></vs-input>
        <vs-input
          type="search"
          style="background-color:#500; border-radius:10px"
          class="my-2 w-full"
          label="template version"
          v-model="template_version"
        ></vs-input>
        <vs-collapse>
          <vs-collapse-item>
            <div style="font-family:gayle" slot="header">
              قبل از استفاده بخوانید!!
            </div>
            <div>
              <h5>حذف کردن ورژن:</h5>
              <br />
              <p>
                برای حذف یک یا چند ورژن تنها کافیست id و تمام ورژن های قبلی
                ایمیج را وارد کرده به غیر از ورژنی که میخواهید حذف شود
              </p>
              <br />
              <h5>اضافه کردن ورژن:</h5>
              <br />
              <p>
                برای افزودن یک یا چند ورژن تمام ورژن های قبلی را وارد کرده و
                درست بعد از انها ورژن جدید را ایجاد کنید
              </p>
            </div>
          </vs-collapse-item>
        </vs-collapse>
        <vs-button v-if="template_id && template_version" class="w-full" @click="updateimage"
          >اعمال</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      template_id: null,
      template_version: null
    };
  },
  methods: {
    updateimage() {
      const data = {
        template_id: this.template_id,
        template_version: this.template_version
      };
      this.$http
        .post("admin/vira/cloud/image/update/version", data)
        .then(result => {
          this.$vs.notify({ text: result.data.message, color: "success" });
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
    }
  }
};
</script>
